import type { ItemModel, DataItemModel } from 'o365.modules.DataObject.Types.ts';

import { DataObject } from 'o365.modules.DataObject.ts';
import DataObjectStorage from 'o365.modules.DataObject.Storage.ts';
import localStorageHelper from 'o365.modules.StorageHelpers.ts';

declare module 'o365.modules.DataObject.ts' {
    interface DataObject<T> {
        /** The BatchData extension responsible for handling `new records` in grids */
        batchData: BatchData<T>;
        /** Indicator that BatchData is created and enabled on this DataObject instance */
        batchDataEnabled: boolean;
    }
}

Object.defineProperties(DataObject.prototype, {
    'batchData': {
        get() {
            if (this._batchData == null) {
                this._batchData = new BatchData(this);
                this._batchData.initialize();
            }
            return this._batchData;
        }
    },
    'batchDataEnabled': {
        get() { return this._batchData != null; }
    },
    'hasNewRecords': {
        get(this: DataObject) { return this.batchDataEnabled && this.batchData.data.length > 0; }
    },
    'current': {
        get(this: DataObject) {
            if (this.currentIndex == null) {
                return {};
            } else if (this.currentIndex < 0 && this.batchDataEnabled) {
                const storageIndex = this.batchData.getInversedIndex(this.currentIndex);
                return this.batchData.storage.data[storageIndex] ?? {};
            } else {
                return this.storage.data[this.currentIndex] ?? {};
            }
        }
    },
    'disableBatchData': {
        get(this: DataObject) {
            // TODO: Depricate in an effort to reduce property count on DataObject
            if (this.batchDataEnabled) {
                return this.batchData.disableBatchData;
            } else {
                return () => {};
            }
        }
    }
});

export default class BatchData<T extends ItemModel = ItemModel> {
    /** Property for tracking if the extension is initialized. */
    private _initialized = false;
    private _dataObject: DataObject<T>;
    /** Save records to local storage instead of the DataBase and commit them all at once */
    private _useLocalStorage: boolean = false;
    /** Debounce for saving to local storage */
    private _localStorageSaveDebounce: number | null = null;

    storage!: DataObjectStorage<T>;

    /** Original setCurrentIndex */
    private _setCurrentIndex!: typeof this._dataObject.setCurrentIndex;
    /** Original createNew */
    private _createNew!: typeof this._dataObject.createNew;
    /** Original deleteItem */
    private _deleteItem!: typeof this._dataObject.deleteItem;
    /** Original save */
    private _save!: typeof this._dataObject.save;
    /** Original cancelChanges */
    private _cancelChanges!: typeof this._dataObject.cancelChanges;
    /** Original refreshRow */
    private _refreshRow!: typeof this._dataObject.refreshRow;

    /** Cancel the DataLoaded event used by this BatchData to clear saved items on each DataObject load */
    private _cancelDataLoadedEvent!: () => void;

    private get storageKey() { return `batchData_${this._dataObject.id}`; }

    /** BatchData items array */
    get data() {
        return this.storage.data;
    }

    /** Count of saved items */
    get rowCount() {
        return this.storage.data.filter(row => !row.isNewRecord).length;
    }

    constructor(pDataObject: DataObject<T>) {
        this._dataObject = pDataObject;
    }

    /** Setup the extension. Should be called right after this instance is created */
    initialize() {
        if (this._initialized) { throw new Error('BatcData is already initialized'); }
        this._initialized = true;
        this.storage = new DataObjectStorage({
            fields: this._dataObject.fields.fields,
            createNewAtTheEnd: true,
            newItemOptionsFactory: () => {
                return {
                    appId: this._dataObject.appId,
                    dataObjectId: this._dataObject.id,
                    fields: this._dataObject.fields.fields,
                    uniqueKeyField: this._dataObject.fields.uniqueField,
                    onSelected: (pIndex, pValue) => { this._dataObject.selectionControl.onSelection(pIndex, pValue); },
                    onValueChanged: (...args) => {
                        this._dataObject.emit('FieldChanged', ...args);
                        const emptyItems = this.storage.data.filter(item => item.isNewRecord && !item.hasChanges);
                        if (emptyItems.length === 0) {
                            this.createNew(undefined, false);
                        } else if (emptyItems.length > 1) {
                            let index = this.getInversedIndex(emptyItems.at(-1)!.index);
                            const previousIsEmpty = this.data[index-1]?.isEmpty;
                            if (!previousIsEmpty) {
                                index = this.getInversedIndex(emptyItems[0]!.index);
                            }
                            const item = this.storage.data[index];
                            this.storage.removeItem(index);
                            if (this.storage.data.at(-1)?.disableSaving) {
                                this.storage.data.at(-1)!.disableSaving = false;
                            }
                            if (item.current) {
                                if (this.data[index] != null) {
                                    this._dataObject.setCurrentIndex(this.getInversedIndex(index), true);
                                } else if (index - 1 >= 0 && this.data[index - 1] != null) {
                                    this._dataObject.setCurrentIndex(this.getInversedIndex(index-1), true);
                                } else {
                                    this._dataObject.unsetCurrentIndex();
                                }
                            } else if (this._dataObject.currentIndex != null && this.getInversedIndex(this._dataObject.currentIndex) > index) {
                                // Items shifted, update current index without state changes
                                this._dataObject.updateCurrentIndex(this._dataObject.currentIndex + 1);
                            }
                        }
                        this._saveToLocalStorage();
                    },
                }
            },
        });
        this.storage.reindex = () => {
            this.storage.data.forEach((item, index) => item.index = this.getInversedIndex(index));
        }

        // Store original DataObject functions
        this._setCurrentIndex = this._dataObject.setCurrentIndex.bind(this._dataObject);
        this._createNew = this._dataObject.createNew.bind(this._dataObject);
        this._deleteItem = this._dataObject.deleteItem.bind(this._dataObject);
        this._save = this._dataObject.save.bind(this._dataObject);
        this._cancelChanges = this._dataObject.cancelChanges.bind(this._dataObject);
        this._refreshRow = this._dataObject.refreshRow.bind(this._dataObject);
        // Wrap DataObject functions with BatchData overrides
        this._dataObject.setCurrentIndex = this.setCurrentIndex.bind(this);
        this._dataObject.createNew = this.createNew.bind(this);
        this._dataObject.deleteItem = this.deleteItem.bind(this);
        this._dataObject.save = this.save.bind(this);
        this._dataObject.cancelChanges = this.cancelChanges.bind(this);
        this._dataObject.refreshRow = this.refreshRow.bind(this);
        this.createNew(undefined, false);

        this._cancelDataLoadedEvent = this._dataObject.on('DataLoaded', () => {
            this.clearSavedItems();
            if (this.data.length == 0) {
                this.createNew(undefined, false);
            }
        });
    }

    /** Set current index override for batch DataObjects */
    setCurrentIndex(...[pIndex, pForceSet]: Parameters<DataObject<T>['setCurrentIndex']>): ReturnType<DataObject<T>['setCurrentIndex']> {
        this.storage.data.forEach(item => item.current = false)

        if (pIndex !== this._dataObject.currentIndex &&  !this._useLocalStorage) {
            this.saveChanges(undefined, false);
        }

        if (pIndex < 0) {
            // Negative indexes should use batch storage
            const storageIndex = this.getInversedIndex(pIndex);
            const item = this.storage.data[storageIndex];
            if (item) {
                item.current = true;
            }
        }

        this._setCurrentIndex(pIndex, pForceSet);
    }

    /** Create new BatchData item */
    createNew(...[pOptions, pSetCurrentIndex]: Parameters<DataObject<T>['createNew']>): ReturnType<DataObject<T>['createNew']> {
        let item: DataItemModel<T> | undefined = undefined;
        if (pSetCurrentIndex == null) {
            pSetCurrentIndex = true;
        }
        const lastRow = this.storage.data.at(-1);
        if (pOptions && lastRow && lastRow.isNewRecord && lastRow.isEmpty) {
            const index = this.getInversedIndex(lastRow.index);
            this.storage.updateOrExtendItem(index, pOptions);
            item = lastRow;
        } else {
            item = this.storage.createNew(pOptions);
            item.state.isBatchRecord = true;
            this.storage.reindex();
        }
        if (pSetCurrentIndex) {
            this.setCurrentIndex(item.index);
        }
        return item;
    }

    /** Delete BatchData item */
    async deleteItem(...[pItem]: Parameters<DataObject<T>['deleteItem']>): ReturnType<DataObject<T>['deleteItem']> {
        if (pItem == null) {
            pItem = this._dataObject.current;
        }
        if (pItem == null) {
            throw new Error(`Cannot delete undefined item`);
        }

        if (pItem.isBatchRecord && pItem.index < 0 && (this._useLocalStorage || !pItem.PrimKey)) {
            this.storage.removeItem(this.getInversedIndex(pItem.index));
            this._saveToLocalStorage();
            return true;
        } else {
            const result = await this._deleteItem(pItem);
            if (pItem.isBatchRecord && result) {
                this.storage.removeItem(this.getInversedIndex(pItem.index));
            }
            return result;
        }
    }

    /** Save BatchData item */
    save(...[pIndex]: Parameters<DataObject<T>['save']>): ReturnType<DataObject<T>['save']> {
        if (pIndex && pIndex < 0) {
            const changes = this.storage.changes.filter(item => item.index === pIndex);
            if (changes.length > 0) {
                return this.saveChanges(changes, false);
            }
        }
        return this._save(pIndex);

    }

    /** Save changes from this BatchData storage */
    async saveChanges(pChanges?: typeof this.data, pClearStorage = true) {
        const changes = (pChanges ?? this.storage.changes)?.filter(x => !x.disableSaving);
        if (changes.length == 0) { return []; }
        let result: T[][] = [];
        if (changes.length > 25) {
            // Await every 25 save requests so that they don't fail due to too many requests
            while (changes.length > 0) {
                const batchChanges = changes.splice(0, 25);
                const batchResult = await this._dataObject.recordSource.saveChanges(batchChanges);
                result.push(...batchResult);
            }
        } else {
            result = await this._dataObject.recordSource.saveChanges(changes);
        }
        if (pClearStorage) {
            this.storage.clearItems();
            this._dataObject.load();
        }
        return result;
    }

    /** Cancel changes in BatchData storage  */
    cancelChanges(...[pIndex, pKey]: Parameters<DataObject<T>['cancelChanges']>): ReturnType<DataObject<T>['cancelChanges']> {
        if (pIndex == null) {
            this.storage.cancelChanges();
        }
        if (pIndex && pIndex < 0) {
            const storageIndex = this.getInversedIndex(pIndex);
            this.storage.cancelChanges(storageIndex, pKey);
        } else {
            return this._cancelChanges(pIndex, pKey);
        }
    }

    /** Helper function to convert between negative and storage indexes */
    getInversedIndex(pIndex: number) {
        const index = (pIndex + 1) * -1;
        return index ? index : 0;
    }

    /** Discard this BatchData instance and restore original DataObject functions */
    disableBatchData() {
        this._cancelDataLoadedEvent();
        this._dataObject.setCurrentIndex = this._setCurrentIndex;
        this._dataObject.createNew = this._createNew;
        this._dataObject.deleteItem = this._deleteItem;
        this._dataObject.save = this._save;
        this._dataObject.cancelChanges = this._cancelChanges;
        this._dataObject.refreshRow = this._refreshRow;
        (this._dataObject as any)._batchData = null;
    }

    /** Clear saved items from storage that are saved */
    clearSavedItems() {
        const toRemove: number[] = [];
        this.data.forEach((item, index) => {
            if (!item.isNewRecord) { toRemove.unshift(index); }
        });
        toRemove.forEach(index => {
            this.storage.data.splice(index, 1);
        });
        this.storage.reindex();
        this.storage._storageUpdated();
    }

    async refreshRow(...[pIndex]: Parameters<DataObject<T>['refreshRow']>): ReturnType<DataObject<T>['refreshRow']> {
        if ((pIndex ?? this._dataObject.currentIndex ?? 0) < 0) {
            const index = this.getInversedIndex(pIndex ?? this._dataObject.currentIndex!);
            const row = this.storage.data[index];
            if (row == null || row.primKey == null) { return undefined; }
            const data = await this._dataObject.recordSource.getRowByPrimKey(row.primKey);
            return data ? this.storage.updateItem(index, data[0], true) : undefined;
        } else {
            return await this._refreshRow(pIndex);
        }
    }

    /**
     * Instead of automaticly saving items to the database, save them to the local storage. 
     * To save to the database items will need to be commited together in a bulk insert.  
     * WARNING: Will clear current batch data items from the storage
     */
    enableLocalStorage() {
        this._initDataFromLocalStorage();
    }

    private _saveToLocalStorage() {
        if (!this._useLocalStorage) { return; }

        if (this._localStorageSaveDebounce) { window.clearTimeout(this._localStorageSaveDebounce); }
        this._localStorageSaveDebounce = window.setTimeout(() => {
            localStorageHelper.setItem(this.storageKey, JSON.stringify(this.storage.changes.map(x => x.changes)));
        }, 10);

    }

    private _initDataFromLocalStorage() {
        if (this._useLocalStorage) { return; }
        this._useLocalStorage = true;

        this.storage.clearItems();

        try {
            const localDataJSON = localStorageHelper.getItem(this.storageKey);
            if (localDataJSON) {
                let items: T[] = JSON.parse(localDataJSON);
                if (items.length === 0) { return; }
                const newItems = items.map(_ => this.createNew(undefined, false));
                newItems.forEach((item, index) => {
                    this.storage.updateItem(this.getInversedIndex(item.index), items[index]);
                });

                this.createNew(undefined, false);
            }
        } catch {
            console.warn('Failed to parse local data while setting batch storage');
        }
    }
}
